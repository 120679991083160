<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout, VclList, Stat },
  data() {
    return {
      table: {
        body: null,
        loading: true,
        errored: false,
        empty: false,
      },

      statData: null,
    };
  },
  methods: {
    getSimulations() {
      this.table.body = null;

      this.table.loading = true;
      this.table.errored = false;
      this.table.empty = false;

      api
        .get("energy/contracts")
        .then((response) => {
          if (response.data.status == "success") {
            this.table.body = response.data.list;
          }
        })
        .catch((error) => {
          this.table.errored = error;
        })
        .finally(() => {
          this.table.loading = false;
          if (this.table.body == "" || this.table.body == null) {
            this.table.empty = true;
          }
        });
    },
  },
  mounted() {
    this.getSimulations();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">UC Contratos</h4>
          <div class="page-title-right"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="table.errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="table.empty">{{ t("No records found.") }}</div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-centered table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Tipo</th>
                    <th>Cliente</th>
                    <th>Média de Consumo</th>
                    <th>Concessionária</th>
                    <th>UC</th>
                    <th>Desconto</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td, index) in table.body" :key="index">
                    <td>{{ td.code }}</td>
                    <td>
                      <template v-if="td.type == 'natural'">PF</template>
                      <template v-else>PJ</template>
                    </td>
                    <td>
                      {{ td.name }}<br>
                      {{ td.city }}/{{ td.state }}
                    </td>
                    <td>
                      {{ td.average_consumption }} KWh
                    </td>
                    <td>
                      {{ td.company }}
                    </td>
                    <td>
                      <template v-if="td.uc">{{ td.uc }}</template>
                      <template v-else>–</template>
                    </td>
                    <td>
                      {{ td.discount }}
                    </td>
                    <td>
                      <span
                        v-if="td.color"
                        :style="'background-color: #' + td.color + '; color: #fff;'"
                        class="badge badge-light font-size-12"
                        >{{ td.status }}</span
                      >
                      <span
                        v-else
                        class="badge badge-light font-size-12"
                        >{{ td.status }}</span
                      >
                      <br>
                      {{ td.date }}
                    </td>
                    <td class="text-right">
                      <router-link
                        :to="'/energy/contracts/' + td.id"
                        class="btn btn-default btn-sm btn-rounded"
                      >
                        Ver
                        <i class="mdi mdi-arrow-right ml-1"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
